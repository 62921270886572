import { template as template_c2fac436fc0c49859099e570588cceff } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const RepliesCell = template_c2fac436fc0c49859099e570588cceff(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="posts"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="replies"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default RepliesCell;
