import { template as template_41a9a758ded248268b73e221934258dc } from "@ember/template-compiler";
const WelcomeHeader = template_41a9a758ded248268b73e221934258dc(`
  <div class="login-welcome-header" ...attributes>
    <h1 class="login-title">{{@header}}</h1>
    {{yield}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default WelcomeHeader;
