import { template as template_82b6445ae3fb4686a31574774551660d } from "@ember/template-compiler";
import icon from "discourse-common/helpers/d-icon";
const InputTip = template_82b6445ae3fb4686a31574774551660d(`
  <div
    class="tip {{if @validation.failed 'bad' 'good'}}"
    id={{@id}}
    ...attributes
  >
    {{#if @validation.reason}}
      {{icon (if @validation.failed "xmark" "check")}}
      {{@validation.reason}}
    {{/if}}
  </div>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default InputTip;
