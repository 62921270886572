import { template as template_38aa0e2b3fb74d129e63e535785bfd49 } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_38aa0e2b3fb74d129e63e535785bfd49(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
