import { template as template_a60e57d9b4894dca84038ee11d3b91bc } from "@ember/template-compiler";
const FKControlMenuContainer = template_a60e57d9b4894dca84038ee11d3b91bc(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
