import { template as template_2c03aabc406d457aa661ee9d136107c1 } from "@ember/template-compiler";
import { on } from "@ember/modifier";
import autosize from "autosize";
import { modifier as modifierFn } from "ember-modifier";
import autoFocus from "discourse/modifiers/auto-focus";
const resize = modifierFn((element)=>{
    autosize(element);
    return ()=>autosize.destroy(element);
});
const ExpandingTextArea = template_2c03aabc406d457aa661ee9d136107c1(`
  <textarea
    {{autoFocus}}
    {{resize}}
    {{! deprecated args: }}
    autocorrect={{@autocorrect}}
    class={{@class}}
    maxlength={{@maxlength}}
    name={{@name}}
    rows={{@rows}}
    value={{@value}}
    {{(if @input (modifier on "input" @input))}}
    ...attributes
  ></textarea>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default ExpandingTextArea;
